@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Rubik:300,400,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/*eslint-disable*/
.row:after, .row:before{
    display: inline-block;
}
.btn.mar-t-2 {
    margin-top: 32px;
    margin-top: 2rem;
}
a.btn.mar-t-2:hover{
    background-color:#f3eaff;
    color:#48194e;
}
.btn.mar-t-6 {
    margin-top: 96px;
    margin-top: 6rem;
}
.mar-t-3 {
    margin-top: 48px;
    margin-top: 3rem;
}
textarea {
    resize: none;
}
body {
    background-color: #ebebeb;
}
section {
    width: 100%;
}
.p-0 {
    padding: 0 !important;
}
.right {
    text-align: right;
}
.mar-b-3 {
    margin-bottom: 48px;
    margin-bottom: 3rem;
}
.mar-b-6 {
    margin-bottom: 96px;
    margin-bottom: 6rem;
}
.outer-wrapper {
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 70px;
}
.inner-wrapper {
    min-height: 730px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
            align-content: center;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.wrapper {
    margin: 0 auto;
    max-width: 850px;
    padding: 40px 24px;
}
.test-wrapper{
    position: relative;
    background-color: #fff;
    padding: 20px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
            box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    min-height: calc(70vh + 100px);
}
.test-portal-footer-content {
    padding: 22px 30px;
    text-align: right;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: 1rem;
}
.test-portal-content-section{
    padding: 30px 0;
}
.content-wrapper {
    width: 100%;
    position: relative;
    padding-top: 80px;
    background-color: #f9f9ff;
}
.subtitle {
    font-size: 32px;
    font-size: 2rem;
    font-weight: lighter;
}
.form-group {
    display: block;
    width: 100%;
    margin: 0 0 20px;
    position: relative;
}
.btn {
    border-radius: 2px;
    width: auto;
    min-width: 100px;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    font-size: 13pt;
    color: #fff;
    text-align: center;
    background: #48194e;
    outline: none;
    cursor: pointer;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    padding: 0 2rem;
    font-weight: lighter
}
.btn:hover, .btn:focus {
    color: #48194e;
    background: #f3eaff;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.submit-ans-block{
    position: fixed;
    bottom: 0;
    padding: 2rem 0;
    background-color: #f8f8f8;
}
.question-answer-block {
    height: calc(60vh + 55px);
    overflow-x: hidden;
    overflow-y: auto;
}
/*  header css start */

.header-fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9999;
}
.navigation-bar {
	padding: 0 ;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	position: relative;
	-ms-flex-direction: row;
	-webkit-flex-direction: row;
	        flex-direction: row;
	background-color: #fff;
	-webkit-box-shadow: 0 0 15px 0 rgba(27,59,118,.1);
	        box-shadow: 0 0 15px 0 rgba(27,59,118,.1);
    display: flex;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 70px;
}
.navigation-menu{
    line-height: 80px;
}
.brand-wrapper {
	width: auto;
    text-align: center;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}
.brand-wrapper:after {
	position: absolute;
	right: -10px;
	content: "";
	top: 25%;
	height: 55%;
	border-right: 1px solid #ddd;
}
.brand-wrapper img {
	width: 180px;
}
.navigation-menu.nav-right {
    margin-left: auto;
}
.navigation-menu.nav-right .finish-btn-wrapper::after {
	position: absolute;
	left: 0;
	content: "";
	top: 25%;
	height: 55%;
	border-right: 1px solid #ddd;
}
.drive-name-header p {
	margin: 0;
	font-size: 20px;
	text-transform: capitalize;
    color: #48194e;
    padding: 0 1rem;
}
.brand-image a {
    display: block;
}
.navbar-menu {
    vertical-align: middle;
    display: inline-block;
    padding: 0 15px;
}
.navbar-menu li {
    display: inline-block;
    margin: 0 5px;
}
.navbar-menu li .nav-links {
    font-size: 17px;
    font-weight: lighter;
    color: #2b2b2b;
    padding: 16px;
    padding: 1rem;
    position: relative;
}
.navbar-menu li .nav-links.boxed-links {
	background:#D2322D;
	color: #fff;
	border-radius: 2px;
	padding: 0 32px;
	padding: 0 2rem;
	min-height: 42px;
	display: block;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	line-height: 42px;
	-webkit-transition: 0.4s ease all;
	transition: 0.4s ease all;
	min-width: 180px;
	text-align: center;
	font-weight: bold;
	font-family: "Roboto",Helvetica,Arial,sans-serif;
	font-size: 16px;
	border-radius: 3px;
}
.navbar-menu li .nav-links.boxed-links:hover, .navbar-menu li .nav-links.boxed-links:focus {
    background: #ac1212;
    color: #ffffff;
}

.overview-header .drive-name-header h2 {
    margin: 0;
    color: #48194e;
    line-height: 70px;
    padding: 0 10px;
    font-weight: normal;
}
.center-box {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	width: 100%;
}
.center-box2 {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-pack: justify;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	width: 100%;
}
.remaining-time-head {
	font-size: 16px;
	color: #666;
	font-weight: lighter;
	vertical-align: middle;
	display: inline-block;
	margin: 0 12px 0 0;
}
.remaining-time-box {
	vertical-align: middle;
	display: inline-block;
}
.remaining-time-box span.time {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 9px;
    font-weight: bold;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
}
.inline.left.responsive .count {
	border: 1px solid #ddd;
	width: 50px;
	height: 40px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
	border-radius: 3px;
	margin: 0 8px;
	line-height: 36px;
	font-weight: bold;
	background: #fffdfd !important;
	font-family: "Roboto",Helvetica,Arial,sans-serif;
	font-size: 18px;
}
@media screen and (max-width: 1368px) {
    .content-wrapper{
        padding-top: 165px;
    }
    .navigation-menu.nav-right {
        margin-left: 0;
    }
    .navigation-bar, .navigation-menu.nav-left, .navigation-menu.nav-right {
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    #navigation-menu-right {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0 1rem;
        background-color: #f7f7f7;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .navigation-menu.nav-right .finish-btn-wrapper::after {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .navigation-bar{
        border-bottom: 1px solid #ddd;
    }
    .navigation-menu.nav-right {
        margin-left: 0;
    }
    .navbar-menu li .nav-links.boxed-links {
        max-width: 150px;
        margin: 10px auto;
    }
    .subtitle {
        font-size: 1.8rem;
    }
    .question-answer-block {
        height: auto;
    }
    .react-confirm-alert-body {
        max-width: 100%;
        width: 92%;
        margin: 0 auto;
    }
    .test-sidebar {
        width: 100%;
        height: auto;
        position: relative;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
        border-bottom: 1px solid #ddd;
    }  
    .sidebar-inst {
        display: none;
    }
    .mobile-only-progress {
        display: inline-block;
    }
    .progress-circle {
        display: none;
    }
    .test-sidebar.left-test-sidebar {
        left: 0;
        border-right: none;
        border-bottom: none;
    }
    .test-progress-block .sidebar-head {
        margin: 0;
        padding: 5px 0;
    }
    .center-box {
        padding: 0 14px;
    }
    .test-counter-outer {
        text-align: center;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; 

    }
}

/*  header css end */

.complete-status {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
            align-content: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
    -ms-grid-column-align: center;
        justify-items: center;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.complete-content h2 {
    margin-bottom: 0px;
    margin-bottom: .0rem;
    font-size: 22px;
    color: #48194e;
}

.incomplete-lable {
    font-size: 17px;
    margin-left: 5px;
    color: #ff3b21;

}

.one-field-status {
    display: block;
    background-color: #f9f9f9;
    cursor: pointer;
    padding: 15px;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 32px 0;
    margin: 2rem 0;
    height: 85px;
}

.one-field-status h3 {
    margin: 0;
}


/*  User profile css end */

.navbar-menu li .nav-links:hover {
    color: #0b4dad;
}
.select-wrapper {
    position: relative;
}

.select-wrapper select.form-ctrl {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding-top: 24px;
    padding-top: 1.5rem;
    position: relative;
    background: #fff;
    z-index: 99;
    color: #999;
    padding-top: 11px;
}

.select-wrapper:after {
    position: absolute;
    content: "";
    right: 10px;
    height: 5px;
    border-right: 8px solid transparent;
    top: calc(50% - 4px);
    border-left: 8px solid transparent;
    border-top: 8px solid rgba(3, 27, 78, 0.4);
    z-index: 999;
}

.help-note {
    color: #b9b9b9;
    margin-bottom: 50px;
}

/* custom checkbox */
.cstm-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    top: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cstm-checkbox input {
    position: absolute;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #48194e;
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.cstm-checkbox input:checked ~ .checkmark {
    background-color: #48194e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cstm-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.exp-check-col {
    position: relative;
    top: 40px;
    right: 0px;
}

/* Style the checkmark/indicator */
.cstm-checkbox .checkmark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* apply Manually css end here */

.page-heading-outer.mar-b-6 {
    margin-bottom: 96px;
    margin-bottom: 6rem;
}

.box-image {
    margin-bottom: 32px;
    margin-bottom: 2rem;
    position: relative;
}

.box-image:after {
    position: absolute;
    background: rgba(52, 97, 153, 0.3);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}

.box-content {
    padding: 0 16px 32px;
    padding: 0 1rem 2rem;
}

.box-title {
    font-size: 35.2px;
    font-size: 2.2rem;
    margin: 0 0 16px;
    margin: 0 0 1rem;
}
.full-link-hover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 99;
}
/* job page css end here */
.text-center {
    text-align: center;
}
.arrow-bottom-links {
    position: absolute;
    right: 48px;
    right: 3rem;
    bottom: 32px;
    bottom: 2rem;
    vertical-align: middle;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}
/* job detail css starts here */

.post-detail-header {
    margin: 0 0 64px;
    margin: 0 0 4rem;
}

.post-content h2 {
    font-size: 32px;
    font-size: 2rem;
    margin: 0 0 32px;
    margin: 0 0 2rem;
}

.post-content h3 {
    margin: 0 0 16px;
    margin: 0 0 1rem;
    font-size: 28.8px;
    font-size: 1.8rem;
}

.post-content p {
    font-weight: lighter;
    font-size: 28.8px;
    font-size: 1.8rem;
    margin: 0 0 32px;
    margin: 0 0 2rem;
}

.post-content ul, .instructions-list ul {
    padding-left: 48px;
    padding-left: 3rem;
    margin-bottom: 32px;
    margin-bottom: 2rem;
}

.post-content ul li, .instructions-list ul li {
    font-size: 28.8px;
    font-size: 1.8rem;
    margin: 0 0 16px;
    margin: 0 0 1rem;
    position: relative;
    display: block;
    padding-left: 48px;
    padding-left: 3rem;
    font-weight: lighter;
}

.post-content ul li:before, .instructions-list ul li:before {
    position: absolute;
    left: -10px;
    top: -5px;
    content: "+";
    font-weight: 500;
    color: #e8eef6;
    font-size: 15pt;
}

.post-content ul li:after, .instructions-list ul li:after {
    position: absolute;
    left: 0;
    top: 0px;
    content: "+";
    font-weight: 500;
    color: #48194e;
    font-size: 15pt;
}

/* Test instructions css */
.exam-status{
    color:#48194e;
}
.instructions-list ul li{
    font-size: 27.2px;
    font-size: 1.7rem;
}
.instructions-list ul{
    padding-left: 0;
}

/* sidebar css start here */

.sidebar-menu-item {
    margin-bottom: 16px;
    margin-bottom: 1rem;
}

.question-list li {
	display: block;
	padding: 10px;
	border-radius: 100%;
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 1;
	margin: 8px;
	margin: 0.5rem;
	background-color: #ddd;
	font-size: 13px;
	position: relative;
	cursor: pointer;
    font-weight: lighter;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
}
.sidebar-head {
	text-align: left;
	color: #333;
	font-size: 16px;
	margin-bottom: 1rem;
	border-bottom: 1px solid #ddd;
	padding: 7px 0;
}
.summary-li {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 100%;
}
.question-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -webkit-justify-content: start;
            justify-content: start;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.sidebar-inst {
    margin-bottom: 32px;
    margin-bottom: 2rem;
}

.sidebar-inst-list li {
	margin-bottom: 0;
	font-size: 15px;
	font-weight: lighter;
	position: relative;
	border-bottom: 1px solid #f7f7f7;
	padding: 10px 50px 10px 10px;
}
.summary-li span.summary-no {
	display: inline-block;
	position: absolute;
	right: 10px;
	font-weight: bold;
}
.sidebar-inst.legends-div .sidebar-inst-list li {
	padding: 10px 8px;
}
.question, .question h3, .question p, .question h2, .question h4 {
	font-size: 18px;
	font-weight: bold;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	word-wrap: break-word;
	font-family: Roboto,Helvetica,Arial,sans-serif;
	margin: 0 0 10px;
}

.question-no {
	font-size: 18px;
	margin-right: 10px;
	font-weight: 400;
	min-width: 50px;
}
.question-content {
	width: calc(100% - 70px);
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.question-content pre {
	font-size: 14px;
	line-height: 1.1;
	background: #f5f5f5;
	width: 100%;
	display: block;
	padding: 10px;
	border: 1px solid #d8dadf;
	border-radius: 3px;
	margin: 5px 0;
}
.question-content ol {
	margin: 10px 0;
	padding-left: 24px;
}
.question .question-content li {
	font-weight: lighter;
	font-size: 16px;
	font-family: "Rubik",Helvetica,Arial,sans-serif;
	margin: 0 0 5px;
	font-style: normal;
}
.test-question-image-ul img {
	border: 1px solid #ddd;
}
.legends {
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: inline-block;
    margin-right: 16px;
    margin-right: 1rem;
    vertical-align: middle;
    border-radius: 100%;
}

.test-sidebar {
	position: fixed;
	height: 100%;
	width: 250px;
	overflow-x: hidden;
	padding-top: 72px;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.07);
	        box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.07);
	overflow-y: auto;
	top: 0;
    bottom: 0;
    z-index: 99;
}
.test-sidebar.left-test-sidebar{
    left: 0;
	border-right: 1px solid #ddd;
}
.right-test-sidebar {
	right: 0;
	border-left: 1px solid #ddd;
}

.sidebar-menu {
    padding: 10px;
}

.sidebar-list li a {
    text-decoration: none;
    display: block;
    color: #5d5d5d;
}

.question-list li.active span, .question-list li.completed span, .question-list li.review span,  .question-list li.reviewanswered span{
    color: #fff;
}

.question-list li:hover, .question-list li.active {
    background-color: #007be3;
}

.question-list li.completed {
    background-color:#3EAA3E;
}
.question-list li.completed:hover {
	background-color: #197b19;
}
.question-list li.reviewanswered {
    background-color: #3EAA3E;
}
.question-list li.reviewanswered:hover {
	background-color: #197b19;
}
.question-list li.Unanswered {
    background-color: #cacfd7;
}
.question-list li.Unanswered:hover {
	background-color: #a2b1ca;
}
.question-list li.review {
    background-color: #7B37BF;
    color: #fff;
}
.question-list li.review:hover {
	background-color: #62299B;
}
.question-list li.reviewanswered:after {
	position: absolute;
	content: "";
	border: 4px solid #8033CC;
	left: -1px;
	right: -1px;
	top: -1px;
	bottom: -1px;
	border-radius: 100%;
}
.legends.completed {
    background-color: #3EAA3E;
}
.legends.reviewanswered {
    background-color: #3EAA3E;
    border:3px solid #8033CC;
}
.legends.reviewactive {
	background-color: #156ce8;
	border: 3px solid #8033CC;
}
.legends.review {
    background-color:#8033CC;
}

.legends.active {
    background-color: #48194e;
}

.legends.Unanswered {
    background-color: #a2b1ca;
}

.sidebar-menu {
	height: 100%;
	overflow-y: auto;
}
/* sidebar css end here */

/* material-icon css */
i.material-icons {
    position: relative;
    /* top: 5px; */
}

.menu-group i.material-icons {
    font-size: 20pt;
}

.menu-group i {
    display: inline-block;
    text-align: center;
    font-size: 15pt;
    vertical-align: middle;
    min-width: 60px;
}

/* material-icon css end */

/* question paper css start */

.test-paper-heading {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -webkit-justify-content: start;
            justify-content: start;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.test-paper-heading h2 {
    font-weight: normal;
    margin: 0;
}

.test-paper-questions {
    margin: 1rem 0;
    padding: 1rem;
}
.test-paper-inst{
    margin: 3rem 0;
    padding: 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.test-question-image-ul {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-pack: distribute;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top: 1rem;
	-ms-flex-pack: left;
	    -webkit-justify-content: left;
	        justify-content: left;
}
.test-question-image-ul li{
    width: 200px;
    display: -ms-grid;
    display: grid;
    margin: 16px 32px;
    margin: 1rem 2rem;
    height: 100%;
    background-color: #eee;
}
.Answer-div {
    margin: 64px 0;
    margin: 4rem 0;
}

.option-1 {
	margin-bottom: 2rem;
	width: 100%;
	cursor: pointer;
	padding: 12px 16px;
	display: inline-block;
	border: 1px solid #eee;
	border-radius: 3px;
	font-weight: lighter;
	-webkit-transition: 0.2s ease all;
	transition: 0.2s ease all;
}
.option-1 .answer-content {
	display: inline-block;
	width: calc(100% - 55px);
}

.option-1:hover {
    border-color: #48194e66;
    background-color: #c1daff33;
}
.option-1.active {
	border-color: #2EA82E;
	background-color: #F6FFF6A1;
}
.list-number {
	width: 30px;
	height: 30px;
	display: inline-block;
	text-align: center;
	margin-right: 1.5rem;
	border-radius: 100%;
	border: 2px solid #ddd;
	color: #aaa;
	vertical-align: top;
	line-height: 26px;
    font-size: 14px;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
}
.option-1:hover .list-number {
    border-color: #48194e;
}
.option-1.active .list-number  {
	border-color: #2EA82E;
    background-color: #2EA82E;
    color: #ffffff;
}
.test-overview .overview-items{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* flex-wrap: wrap; */
    -ms-flex-pack: distribute;
        -webkit-justify-content: space-around;
            justify-content: space-around;
    -ms-flex-align: center;
        -webkit-align-items: center;
            align-items: center;
    width: 100%;
}
.test-overview .overview-items li{
    display: inline-block;
    padding: 10px 20px;
    max-width: 100%;
    margin: 16px 0;
    margin: 1rem 0;
    text-align: center;
    color: #757575;
    -ms-flex-positive: 1;
        -webkit-flex-grow: 1;
            flex-grow: 1;
    border-right: 1px solid #ddd;
}
.test-overview .overview-items li:last-child{
    border: none;
}
.test-overview .overview-items li p{
    font-size: 24px;
    color: #48194e;
}
.question-list li.markedreview.active {
	background-color: #48194e;
	color: #fff;
}
.question-list li.markedreview.active::after {
	position: absolute;
	left: -2px;
	right: -2px;
	content: "";
	top: -2px;
	bottom: -2px;
	border-radius: 100%;
	border: 3px solid #7B37BF
}
/* question paper css end */

/*  dashboad footer */
.footer-wrapper {
    padding: 15px;
    background-color: #e7e9ec;
}

/* dashboad footer end */

/* toggle sidebar */
.sidebar-toggle-box {
    width: 25px;
    position: relative;
    font-size: 0;
    cursor: pointer;
    margin-right: 15px;
    display: block;
}

.sidebar-toggle-box span {
    height: 2.5px;
    background: #212529;
    width: 100%;
    display: block;
    top: 22px;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: absolute;
}

.sidebar-toggle-box span:nth-child(2) {
    top: 30px;
}

.sidebar-toggle-box span:nth-child(3) {
    top: 38px;
}

/* toggle sidebar end */

/* user dashboard navbar css */
.dash-heading {
    padding: 15px;
    background-color: #48194e;
    color: #fff;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.dash-heading h3 {
    margin: 0;
    font-weight: lighter;
}

.dash-heading a, .dash-heading a:hover {
    color: #fff;
    font-size: 20px;
}

.dash-navbar {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
        -webkit-align-items: center;
                align-items: center;
    position: relative;
}

.dash-navMenu {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.dash-navMenu li {
    display: inline-block;
    background-color: #eff5fe;
    border-bottom: 2px solid #fff;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: center;
}

.dash-navMenu li a {
    font-size: 17px;
    padding: 15px;
    display: block;
    color: #000;
    font-weight: lighter;
}

.dash-navMenu li:hover, .dash-navMenu li.active {
    border-color: #48194e;
    background-color: #e3edfb;
}

.dash-navMenu li:hover a, .dash-navMenu li.active a {
    color: #48194e;
}

.dash-navMenu li a i.material-icons, .dash-heading a i.material-icons {
    position: relative;
    top: 6px;
}

/* user dashboard css navbar end */

.dash-status, .dash-side {
    padding: 0 10px;
}

.dash-side {
    margin-top: 72px;
    margin-top: 4.5rem;
}

.current-status h2, .activity-status h2 {
    font-size: 23px;
    color: #547dc5;
    margin-bottom: 16px;
    margin-bottom: 1rem;
}

.progress2 {
    margin-bottom: 32px;
    margin-bottom: 2rem;
    border-radius: 30px;
    background: #eee;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
    height: 10px;
    border-radius: 30px;
    width: 50%;
    background-color: #67b752;
}

.status-div {
    border: 2px solid #eee;
    border-radius: 10px;
    margin: 16px 0 48px;
    margin: 1rem 0 3rem;
    background-color: #f9f9f9;
}

.status-div ul {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.status-div ul li {
    display: inline-block;
    padding: 0 20px;
    max-width: 100%;
    margin: 16px 0;
    margin: 1rem 0;
    text-align: center;
    color: #4d4d4d;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    border-right: 1px solid #ddd;
}

.status-div ul li p {
    margin: 0;
    color: #000;
    font-size: 18px;
}

.upcoming-status h2 {
    font-size: 23px;

}

.upcoming-status h2, .status-div.upcoming h3 {
    color: #547dc5;
    margin-bottom: 8px;
    margin-bottom: 0.5rem;
}

.status-div.upcoming {
    padding: 15px;
    border-color: #547dc5;
    background-color: #fff;
}

.no-activity {
    background-color: #fdfdfd;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 16px 0 48px;
    margin: 1rem 0 3rem;
}

.no-activity h3 {
    font-weight: normal;
    color: #48194e;
    margin-bottom: 0px;
    margin-bottom: 0rem;
    margin-top: 24px;
    margin-top: 1.5rem;
}

.no-activity p {
    margin: 0;
    font-weight: lighter;
    color: #757575;
}

.no-activity img {
    width: 100px;
}

.status-div .dashboard-activity-ul {
    border-bottom: 1px solid #ddd;
}

.status-div .dashboard-activity-ul li {
    text-align: left;
    border: none;
}

.status-div .dashboard-activity-ul li p {
    color: #000;
    display: inline-block;
    font-size: 18px;
}

.drive .status-div.upcoming:first-child {
    border-color: #547dc5;
}

.status-div.upcoming ul {
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.all-test {
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 32px 0 64px;
    margin: 2rem 0 4rem;
    background-color: #fff;
}

.all-test ul li {
    padding: 20px 15px;
    font-size: 18px;
    border-bottom: 2px solid #eee;
}

.all-test ul li.ongoing {
    color: #48194e;
}

.all-test ul li:first-child {
    color: #48194e;
    text-transform: uppercase;
}

.all-test ul li:last-child {
    border-bottom: 0;
}

.all-test ul li span {
    margin-right: 16px;
    margin-right: 1rem;
    color: #aaa;
}

.light-btn, .light-btn:hover {
    display: block;
    background-color: #e3edfb;
    padding: 10px;
    text-align: center;
    margin-top: 48px;
    margin-top: 3rem;
    color: #48194e;
}

.note-status {
    width: 100%;
    color: #c01800;
    font-size: 17px;
    padding: 0 10px;
    text-align: center;
    margin: 80px 0 32px;
    margin: 5rem 0 2rem;
}

/* dashboad content css end */

/* activity page css */
.activity-page {
    margin-bottom: 60px;
    overflow-x: auto;
}

.no-job img {
    width: 150px;
}

.no-job h3 {
    font-weight: normal;
    color: #48194e;
    margin-bottom: 0px;
    margin-bottom: 0rem;
    margin-top: 24px;
    margin-top: 1.5rem;
}

#job-activity {
    border-collapse: collapse;
    width: 100%;
    cursor: pointer;
    /*border: 1px solid #eee;*/
}

#job-activity td, #job-activity th {
    padding: 18px;
    border-bottom: 1px solid #eee;
    font-size: 16px;

}

#job-activity th {
    background-color: #f8f8f8;
}

.activity-list li {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    color: #757575;
    font-weight: lighter;
    overflow-wrap: break-word;
    border: 1px solid #f6f6f6;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0 6px 0 rgba(0, 0, 0, .04);
            box-shadow: 0px 0 6px 0 rgba(0, 0, 0, .04);
    margin-bottom: 48px;
    margin-bottom: 3rem;
    background-color: #fff;
}

.activity-list li .activity-detail {
    color: #333;
}

.activity-list li i {
    color: #48194e;
    width: 40px;
    text-align: center;
    top: 6px;
}

.actvity-time {
    /*float: right;*/
    color: #757575;
    padding: 0 10px;
    margin: 8px 0;
    margin: .5rem 0;
    font-weight: lighter;
}

/* activity page css end */

/* hiring process page css start */
.p-0 {
    padding: 0;
}

.hiring-wrapper-div {
    margin-bottom: 80px;
    margin-bottom: 5rem;
}

.hiring-step {
    padding: 0 25px;
    background-color: #f9f9f9;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
}

.hiring-step h2 {
    margin-bottom: 16px;
    margin-bottom: 1rem;
    font-size: 32px;
    font-size: 2rem;
    display: inline-block;
    color: #333;
}

.hiring-head {
}

.hiring-step span {
    font-size: 35.2px;
    font-size: 2.2rem;
    font-weight: 500;
    color: #012732;
    margin-right: 16px;
    margin-right: 1rem;
}

.row-wrapper {
    position: relative;
    background-color: #f9f9f9;
    padding: 25px 25px 10px;
    margin-bottom: 48px;
    margin-bottom: 3rem;
    border: 1px solid #eee;
}

.cross-btn {
    position: absolute;
    top: -7px;
    right: -9px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    background: #eee;
    height: 25px;
    font-size: 10px;
    border-radius: 100%;
}

.cross-btn i.material-icons {
    font-size: 18px;
}

/* hiring process page css end */

.heading-layout {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
}

.heading-layout h3 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: lighter;
}

.section-wrapper {
    -webkit-box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.07);
            box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    background: white;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

table * {
    position: relative;
}

table th, table td {
    padding: 12px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
}

table td {
    text-transform: none;
    color: #4d4d4d;
    border-color: #da1313;
    border-bottom: 1px solid #eee;
}

tbody tr:hover {
    color: #555555;
    background-color: #f7f7f7;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
}

table thead th {
    border-color: #da1313;
    border-bottom: 2px solid #eee;
}

.action-btn {
    background-color: #48194e;
    border: 0;
    color: #fff;
    margin: 2px;
    min-width: 63px;
    border-radius: 25px;
    padding: 1px 5px;
}

.action-btn.delete {
    background-color: #ff2d2d;
}

.table-inside-btn, .table-inside-btn:hover, .table-inside-btn:focus {
    background-color: #efefef;
    padding: 4px 15px;
    color: #000;
    font-weight: lighter;
    margin: 32px 0 0;
    margin: 2rem 0 0;
    display: inline-block;
    border-radius: 3px;
    font-size: 13px;
    min-width: 120px;
    text-align: center;
}

.column1 {
    width: 6%;
}

.column2 {
    width: 20%;
}

.column3 {
    width: 30%;
}

.column5 {
    width: 12%;
}

/******* table css end *******/

.navbar-collapse.sidebar {
    width: 49px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    white-space: nowrap;
    overflow: hidden;
}

.navbar-collapse .menu-group i {
    text-align: left;
}

.collapseFdmin-content {
    margin-left: 50px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

#toggle-btn {
    position: absolute;
    left: 257px;
    top: 72px;
    background-color: #0384ae;
    border-radius: 0;
    color: #fff;
    width: 30px;
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

#toggle-btn.move {
    -webkit-transform: translateX(-210px);
    transform: translateX(-210px);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.divider {
    background-color: #ddd;
    height: 1px;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
}

.coming-soon-page {
    text-align: center;
    vertical-align: middle;
    min-height: calc(100vh - 165px);
    padding: 87px 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
            align-content: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
}

.coming-soon-page h2 {
    font-size: 60px;
    display: inline-block;
    color: #48194e;
    margin: 0;
}

.coming-soon-page p {
    padding: 0 30px;
    font-size: 22px;
    color: #757575;
}
.coming-soon-page img {
    width: 550px;

}
#profileCompletedTotal {
    width: 100px;
    height: 100px;
}

#profileCompletedPersonal, #profileCompletedEducation, #profileCompletedExperience, #profileCompletedBasic {
    height: 30px !important;
}

#profileCompletedTotal > svg, #profileCompletedPersonal > svg, #profileCompletedEducation > svg, #profileCompletedExperience > svg, #profileCompletedBasic > svg {
    width: 100% !important;
    display: block;
}

#profileCompletedPersonal > svg, #profileCompletedEducation > svg, #profileCompletedExperience > svg, #profileCompletedBasic > svg {

    width: 100% !important;
    height: 20px !important;
    display: block;
}

/*popup for editing and creating pool.....*/
.overlay {
    z-index: 9999 !important;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    /*transition: opacity 500ms;*/
    display: none;
}

.popup {
    margin: 0px auto;
    -webkit-transform: translateY(80px);
    transform: translateY(80px);
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    max-width: 400px;
    position: relative;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.overlay:target .popup {
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
}

.popup-heading {
    border-bottom: 1px solid #eee;
}

.popup-heading h4 {
    margin-bottom: 16px;
    margin-bottom: 1rem;
}

.popup .close {
    position: absolute;
    top: 10px;
    right: 15px;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
    padding: 20px 0;
}

.Logout-account .popup .content, .Signout-account .popup .content {
    text-align: center;
    font-size: 18px;
}
.user-score-div {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
            align-content: center;
    min-height: 142px;
}

.progress-status {
    width: 120px;
    background-color: #fcfcfc;
    border: 15px solid #48194e;
    border-radius: 100%;
    height: 120px;
    text-align: center;
    font-size: 20px;
    line-height: 90px;
    vertical-align: middle;
}

/* media queries */

@media screen and (max-width: 440px) {
    .otp-ul input {
        max-width: 30px;
        min-height: 30px !important;
        max-height: 30px !important;
    }
    .activity-page {
        width: 100%;
        overflow-y: hidden;
        border: 1px solid #ddd;
    }
    .popup {
        margin: 0 20px;
    }
}
/*react alert PopUp*/
.drop-down-menu .react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: auto;
    padding: 80px 47px;
    text-align: left;
    background: #fff;
    border-radius: 21px;
    -webkit-box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
            box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}
.drop-down-menu .react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 10px 20px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}
.submit-answer-loader-outer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(178, 188, 211, 0.2);
	-ms-flex-pack: center;
	        -webkit-justify-content: center;
	        justify-content: center;
	-ms-flex-line-pack: center;
	-webkit-align-content: center;
	        align-content: center;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	height: 100%;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.submit-answe-laoder-content p {
	margin: 12px 0;
	display: block;
	font-size: 15px;
	color: #333333;
}
.submit-answe-laoder-content {
	display: block;
	text-align: center;
}
.Question-section {
	position: relative;
	min-height: 450px;
}

.react-dailog-box-aside {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-pack: center;
	        -webkit-justify-content: center;
	        justify-content: center;
	-ms-flex-line-pack: center;
	-webkit-align-content: center;
	        align-content: center;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	background: #958d8d3b;
	z-index: 9999;
}
.readt-dialog-box-content h2 {
	font-size: 32px;
	font-size: 2rem;
	font-weight: bold;
	margin: 0;
}
.readt-dialog-box-content p {
	font-weight: lighter;
	font-size: 14px;
	margin: 0 0 32px;
	margin: 0 0 2rem;
}
.readt-dialog-box-dialog {
	background: #fff;
	-webkit-box-shadow: 0 0 3px 0px #878c93;
	        box-shadow: 0 0 3px 0px #878c93;
	border-radius: 4px;
	width: 450px;
	max-width: 100%;
}
.readt-dialog-box-content {
	text-align: center;
	padding: 32px;
	padding: 2rem;
}
/* test portal footer  */
.test-portal-footer {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
    z-index: 9;
}
.btn.signin.save-btn {
	background: #399D39;
	margin-left: 20px;
}
.btn.finish-btn {
	background: #f13624;
    padding: 1 2rem; 
}
.finish-btn-wrapper{
    min-width: 160px;
    text-align: center;
    position: relative;
}
.btn.signin.save-btn:hover {
	background: #197b19;
}
.btn.marked-review-btn {
	background: #7B37BF;
}
.btn.marked-review-btn:hover {
	background: #4F1A82;
}
/* test-portal -header */
.test-portal-header {
	border-bottom: 1px solid #ddd;
	position: relative;
	margin-bottom: 16px;
}
.test-section-list .sec-name {
	display: inline-block;
	-ms-flex-pack: justify;
	        -webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 8px 12px;
	position: relative;
	font-weight: normal;

	margin: 0 5px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: lighter;
}
.test-section-title .sec-name {
	display: inline-block;
	-ms-flex-pack: justify;
	        -webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 8px 12px;
	position: relative;
	font-weight: normal;
	margin: 0 5px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: lighter;
}

.test-section-title .sec-name:hover:after, .test-section-title .sec-name.active:after {
	height: 3px;
}
.test-section-title  .sec-name.active, .test-section-title  .sec-name.active:hover {
	background: #f1f3f7;
	color: #48194e;
}

.test-section-list .sec-name:after {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -1.5px;
	height: 0;
	content: "";
	background: #48194e;
	-webkit-transition: height 0.3s ease;
	transition: height 0.3s ease;
}
.test-section-list .sec-name.active:after {
	height: 3px;
}
.test-section-list .sec-name.active, .test-section-list  .sec-name.active:hover {
	background: #f1f3f7;
	color: #48194e;
}
.pool-question-count {
	margin-left: 4px;
	font-size: 14px;
}
/* answe question block */

.progress-circle {
	text-align: center;
	display: block;
	padding: 10px 50px 10px 10px;
}
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #399D39;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
	font-size: 24px;
	font-weight: bold;
    fill: #399D39;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
}
.mobile-only-progress {
	display: none;
}
.test-overview-wrapper {
	position: relative;
}
.test-overview-wrapper .submit-answer-loader-outer {
	z-index: 99999;
	background: rgba(226, 233, 245, 0.49);
}
.test-overview-wrapper .submit-answer-loader-outer p {
	color: #48194e;
}
.react-confirm-alert .react-confirm-alert-body > h1 {
	color: #333;
	font-size: 24px;
	margin: 0 0 5px;
}
.react-confirm-alert-button-group > button:first-child {
	background: #da1313;
	color: #fff;
}
.react-confirm-alert-button-group > button:first-child:hover {
	background: #a02222;
	color: #fff;
}
.react-confirm-alert-button-group > button:last-child {
	background: transparent;
	color: #48194e;
	border: 1px solid #48194e;
}
.react-confirm-alert-button-group > button:last-child:hover {
	background: #48194e;
	color: #ffffff;
	border: 1px solid #48194e;
}
.btn-group.form-group .btn.disabled{
	cursor: not-allowed;
}
.btn-group.form-group .btn[disabled] {
	cursor: not-allowed;
}
@media(min-width:1025px) and (max-width:1368px){
    .navbar-menu li .nav-links.boxed-links{
        min-width: 120px;
    }
    .test-section-list .sec-name {
        padding: 8px;
        margin: 0 5px;
        font-size: 14px;
    }
}
@media(max-width:640px){
    .subtitle {
        font-size: 1.7rem;
    }
    .post-content ul, .instructions-list ul {
        padding-left: 2rem;
    }
    .brand-wrapper img {
        width: 150px;
    }
    .question-answer-block {
        height: auto;
    }
    .center-box {
        display: block;
    }
    .test-counter-outer {
        text-align: center;
    }
    .remaining-time-head{
        display: none;
    }
    .navbar-menu li .nav-links.boxed-links {
        min-width: 120px;
        margin: 8px auto;
        font-size: 14px;
        min-height: 36px;
        line-height: 36px;
        max-width: 180px;
    }
    .test-overview .overview-items li {
        border-right: none;
        border-bottom: 1px solid #ddd;
        width: 100%;
    }
    .test-overview .overview-items {
        -ms-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        display: block;
    }
    .test-paper-inst{
        padding: 10px;
    }
    .post-content ul, .instructions-list ul {
        padding-left: 0;
    }
}
@media screen and (max-width:767px){
    .header-fixed{
        position: relative;
    }
    .content-wrapper {  
        padding-top: 0px;
    }
    #navigation-menu-right {
        background-color: transparent;
        border-top: none;
        border-bottom: none;
    }
    .navbar-menu {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
                flex-direction: column;
        text-align: center;
        line-height: 60px;
    }
    .navbar-menu li {
        border-top: 1px solid #eee;
    }
}
ul .width-auto {
    width:auto;
}

.container, .container-fluid {
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
}

@-ms-viewport {
  width: device-width; }


*, ::after, ::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

@media (min-width: 1024px) {
  .container, .main-container {
    max-width: 1170px; }
  .wrapper {
    max-width: 850px;
  }
}

@media (min-width: 1369px) {
  .container, .main-container {
    max-width: 1340px;
  }
  .wrapper {
    max-width: 950px;
  }
}
@media (min-width: 1440px) {
  .container, .main-container {
    max-width: 1400px;
  }
  .wrapper {
    max-width: 1072px;
  }
}
.wp-form-wrapper .wpforms-form .wpforms-field-container, .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  -webkit-order: -1;
          order: -1; }

.order-last {
  -ms-flex-order: 13;
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media(min-width:641px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media(min-width:1025px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 641px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1025px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1369px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.errorText {
    color: #CF2030;
    clear: both;
}

.errorText.success {
    color: #318009!important;
    clear: both;
}
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    margin: 0;
    overflow-x: hidden;
    height: 100%;
    font-family: "Rubik",Helvetica,Arial,sans-serif;
    font-size: 13pt;
    line-height: 1.5;
    color: #222222;
    background-color: #ffffff;
    padding: 0;
}
h1,h2,h3,h4,h5{
    font-family: "Roboto",Helvetica,Arial,sans-serif;
    margin: 0 0 2rem;
}
p{
    margin: 0 0 1rem;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden],
template {
    display: none;
}
a {
    background-color: transparent;
}
a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
mark {
    background: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 1em 40px;
}
hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}
pre {
    overflow: auto;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: bold;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td,
th {
    padding: 0;
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
ul{
    margin: 0;
    padding: 0;
}
ul li{
    display: block;
}
a {
    color: #1954AF;
    text-decoration: none;
}
a:hover,
a:focus {
    color: #2E7EED;
    text-decoration: none;
    outline:none;
}
a:focus {
    outline: none;
    outline-offset: -2px;
    text-decoration: none;
}
figure {
    margin: 0;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

